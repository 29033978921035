import React, { useState } from "react";
import LocalizedLink from "../localizedLink";
import useTranslationsCat from "../useTranslationsCat";

const FaqLeftNav = () => {
	const ActiveStyle = {
		color: "#0428a0",
	};
	const {
		thermohygrometers,
		transmitters,
		psychrometers,
		phMeters,
		optionalProbes,
		forSk610ph2,
		forSk620ph2Sk650ph,
		forSk620ph,
	} = useTranslationsCat();

	const [checked, setChecked] = useState(true);
	const handleClick = () => setChecked(!checked);
	const pathName =
		typeof window !== "undefined" ? window.location.pathname : "";

	return (
		<>
			<div className="leftnav faq-leftnav">
				<div className="ac pc leftnav-links">
					<li className={"ac-faq"}>
						{pathName.indexOf("/support/faq/thermohygrometers") !== -1 ? (
							<input
								className="ac-input"
								id="thermohygrometers-info-pc"
								name="thermohygrometers-info"
								type="checkbox"
								checked={checked}
								onClick={() => handleClick()}
								onChange={(e) => {}}
							/>
						) : (
							<input
								className="ac-input"
								id="thermohygrometers-info-pc"
								name="thermohygrometers-info"
								type="checkbox"
							/>
						)}
						<label
							className="ac-label leftnav-link"
							htmlFor="thermohygrometers-info-pc"
						>
							<span>{thermohygrometers}</span>
						</label>
						<section className={`ac-text`}>
							<ul>
								<li className="leftnav-link-child">
									<LocalizedLink
										activeStyle={ActiveStyle}
										to={`/support/faq/thermohygrometers/transmitters/`}
									>
										{transmitters}
									</LocalizedLink>
								</li>
								<li className="leftnav-link-child">
									<LocalizedLink
										activeStyle={ActiveStyle}
										to={`/support/faq/thermohygrometers/psychrometers/`}
									>
										{psychrometers}
									</LocalizedLink>
								</li>
							</ul>
						</section>
					</li>
					<li className="leftnav-link">
						<LocalizedLink
							activeStyle={ActiveStyle}
							to={`/support/faq/ph-meters/`}
						>
							{phMeters}
						</LocalizedLink>
					</li>
					<li className={"ac-faq"}>
						{pathName.indexOf("/support/faq/optional-probes") !== -1 ? (
							<input
								className="ac-input"
								id="optional-probes-info-pc"
								name="optional-probes-info"
								type="checkbox"
								checked={checked}
								onClick={() => handleClick()}
								onChange={(e) => {}}
							/>
						) : (
							<input
								className="ac-input"
								id="optional-probes-info-pc"
								name="optional-probes-info"
								type="checkbox"
							/>
						)}
						<label
							className="ac-label leftnav-link"
							htmlFor="optional-probes-info-pc"
						>
							<span>{optionalProbes}</span>
						</label>
						<section className={`ac-text`}>
							<ul>
								<li className="leftnav-link-child">
									<LocalizedLink
										activeStyle={ActiveStyle}
										to={`/support/faq/optional-probes/for-sk-610ph-2/`}
									>
										{forSk610ph2}
									</LocalizedLink>
								</li>
								<li className="leftnav-link-child">
									<LocalizedLink
										activeStyle={ActiveStyle}
										to={`/support/faq/optional-probes/for-sk-620ph2-sk-650ph/`}
									>
										{forSk620ph2Sk650ph}
									</LocalizedLink>
								</li>
								<li className="leftnav-link-child">
									<LocalizedLink
										activeStyle={ActiveStyle}
										to={`/support/faq/optional-probes/for-sk-620ph/`}
									>
										{forSk620ph}
									</LocalizedLink>
								</li>
							</ul>
						</section>
					</li>
				</div>

				<div className={`ac sp`}>
					<div>
						<input
							className="ac-input"
							id="thermohygrometers-info"
							name="thermohygrometers-info"
							type="checkbox"
						/>
						<label className="ac-label" htmlFor="thermohygrometers-info">
							{thermohygrometers}
						</label>
						<section className={` ac-text ac-text-footer `}>
							<ul className="leftnav-links">
								<li className="leftnav-link">
									<LocalizedLink
										to={`/support/faq/thermohygrometers/transmitters/`}
									>
										{transmitters}
									</LocalizedLink>
								</li>
								<li className="leftnav-link">
									<LocalizedLink
										to={`/support/faq/thermohygrometers/psychrometers/`}
									>
										{psychrometers}
									</LocalizedLink>
								</li>
							</ul>
						</section>
					</div>
					<li className="leftnav-link">
						<LocalizedLink to={`/support/faq/ph-meters/`}>
							{phMeters}
						</LocalizedLink>
					</li>
					<div>
						<input
							className="ac-input"
							id="optional-probes-info"
							name="optional-probes-info"
							type="checkbox"
						/>
						<label className="ac-label" htmlFor="optional-probes-info">
							{optionalProbes}
						</label>
						<section className={` ac-text ac-text-footer `}>
							<ul className="leftnav-links">
								<li className="leftnav-link">
									<LocalizedLink
										to={`/support/faq/optional-probes/for-sk-610ph-2/`}
									>
										{forSk610ph2}
									</LocalizedLink>
								</li>
								<li className="leftnav-link">
									<LocalizedLink
										to={`/support/faq/optional-probes/for-sk-620ph2-sk-650ph/`}
									>
										{forSk620ph2Sk650ph}
									</LocalizedLink>
								</li>
								<li className="leftnav-link">
									<LocalizedLink
										to={`/support/faq/optional-probes/for-sk-620ph/`}
									>
										{forSk620ph}
									</LocalizedLink>
								</li>
							</ul>
						</section>
					</div>
				</div>
			</div>
		</>
	);
};

export default FaqLeftNav;
